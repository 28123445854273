import Axios from './index';
import { isEmpty } from 'Utils/isEmptyObject';
import qs from 'qs';
import { isServerError } from 'Components/atoms/toast/actions';

const client = async (method = 'GET', url = '', data = {}, headers = {}, additionalProperties = {}, bodyType = '') => {
  let apiConfig = { method, url, headers, ...additionalProperties, data };

  if (isEmpty(data) && !bodyType) delete apiConfig.data;

  if (isEmpty(headers)) delete apiConfig.headers;
  try {
    const { data, status } = await Axios(apiConfig);

    if (status >= 200 && status < 300) return { data, fail: false, message: data.message };
  } catch (e) {
    // NOTE - cors error, browser extensioni error tashlasa sayt ochilishi bilan ko'rinyapti
    // TODO - server ishlamayotganini boshqacha yo'l bilan farqlash kerak
    // isServerError(e);
    return {
      data: null,
      fail: true,
      message: e.response?.data?.message
    };
  }
};

class ApiService {
  url = '';
  body = '';
  method = 'GET';
  headers = {};
  params = {};
  responseType = 'json';
  contentType = 'application/json';


  setUrl(url) {
    this.url = url;
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  setMethod(method) {
    this.method = method;
    return this;
  }

  setHeaders(headers) {
    this.headers = headers;

    return this;
  }

  setParams(params) {
    this.params = params;
    return this;
  }

  setResponseType(responseType) {
    this.responseType = responseType;
    return this;
  }

  setContentType(type) {
    this.contentType = type;

    return this;
  }


  async request(cancelToken) {
    Axios.defaults.headers['Content-type'] = this.contentType;

    try {
      const { data, status } = await Axios({
        method: this.method,
        url: this.url,
        data: this.body,
        headers: this.headers,
        params: this.params,
        responseType:this.responseType,
        cancelToken
      });

      this.reset();

      if (status >= 200 && status < 300) return { data, fail: false, message: data.message };
    } catch (e) {
      return {
        data: null,
        fail: true,
        message: e.response?.data.message
      };
    }
  }

  resetParams() {
    this.params = {};
    return this;
  }

  reset() {
    this.url = '';
    this.body = '';
    this.method = 'GET';
    this.headers = {};
    this.params = {};
    this.responseType = 'json';
    this.contentType = 'application/json';
  }
}

const apiService = new ApiService();

export { apiService };

export default client;
