import React, { useEffect, useState } from 'react';
import Modal from 'Components/atoms/modal';
import Button from 'Components/atoms/button';
import { useTranslation } from 'react-i18next';

// Конечная дата в часовом поясе Ташкента (UTC+5)
const END_DATE = new Date('2025-02-27T00:00:00+05:00');

const isValidDateString = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return false;

  // Проверяем формат YYYY-MM-DD
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dateStr)) return false;

  // Проверяем, что это действительно валидная дата
  const date = new Date(dateStr);
  return date instanceof Date && !isNaN(date);
};

const getFormattedDate = (date) => {
  try {
    return date.toISOString().split('T')[0];
  } catch (error) {
    console.error('Error formatting date:', error);
    return new Date().toISOString().split('T')[0];
  }
};

const checkShouldRender = () => {
  try {
    const currentDate = new Date();
    let lastShownDate;

    try {
      lastShownDate = localStorage.getItem('tax225_last_shown_date');
    } catch (error) {
      console.error('Error accessing localStorage:', error);
      lastShownDate = null;
    }

    // Если текущая дата после конечной даты, не показываем модальное окно
    if (currentDate > END_DATE) {
      return false;
    }

    // Проверяем валидность даты из localStorage
    if (!isValidDateString(lastShownDate)) {
      return true;
    }

    // Если дата последнего показа не сегодня, показываем модальное окно
    const today = getFormattedDate(currentDate);
    return lastShownDate !== today;
  } catch (error) {
    console.error('Error in checkShouldRender:', error);
    return false; // В случае ошибки не показываем модальное окно
  }
};

const InfoAboutTax225 = () => {
  const { t } = useTranslation(['modalMessages']);
  const [visible, setVisible] = useState(false);

  const title = t('tax_popaps.225.title');
  const description = t('tax_popaps.225.description');

  const onClose = () => {
    try {
      const today = getFormattedDate(new Date());
      localStorage.setItem('tax225_last_shown_date', today);
      setVisible(false);
    } catch (error) {
      console.error('Error in onClose:', error);
      setVisible(false); // Закрываем модальное окно даже в случае ошибки
    }
  };

  useEffect(() => {
    if (checkShouldRender()) {
      setVisible(true);
    }
  }, []);

  return (
    <Modal title={title} onClose={onClose} visible={visible}>
      <p>{description}</p>
      <Button
        style={{
          width: '100%'
        }}
        onClick={onClose}>
        {t('tax_popaps.225.button')}
      </Button>
    </Modal>
  );
};

export default InfoAboutTax225;
