import React from 'react';
import InfoAboutTax225 from './popaps/info-about-tax-225';

const UserAttentionPopaps = () => {
  return (
    <>
      <InfoAboutTax225 />
    </>
  );
};

export default UserAttentionPopaps;
