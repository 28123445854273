import client from 'Services/api/client';

let BASE_URL = '/document';

const ApiDocument = {
  async getSelectedDocument(id) {
    return await client(undefined, `/documents/get/info/${id}`);
  },
  async getMeasureList() {
    return await client(undefined, '/info/measuries/list');
  },
  async getDocumentList(body) {
    return await client('POST', `/documents/get/data`, body);
  },
  async copy(id) {
    return await client('POST', `${BASE_URL}/create/copy/${id}`);
  },
  async view(id) {
    return await client(undefined, `/documents/get/view/pdf/${id}`, {}, {}, { responseType: 'blob' });
  },
  async getDocumentContent(id) {
    return await client(undefined, `/documents/get/content/${id}`);
  },
  async getEditDocumentById(id) {
    return await client(undefined, `${BASE_URL}/edit-document/${id}`);
  },
  async getSignedDocContent(id) {
    return await client(undefined, `/documents/get/signed/content/${id}`);
  },
  async signTaxDocument(body) {
    // NOTE: dokument listdan sign qilish, 'sign-json' appendPcks7 bilan keladi
    return await client('POST', `${BASE_URL}/accept`, body);
  },
  async signWaybillDocument({ status, documentId, body }) {
    return await client('POST', `/responsible-persons/sign/${status}/${documentId}`, body);
  },
  async signCommonDocument(body) {
    return await client('POST', `${BASE_URL}/accept`, body);
  },
  async rejectDocument(body) {
    return await client('POST', `${BASE_URL}/reject`, body);
  },
  async notApprovedDocument(body) {
    return await client('POST', `${BASE_URL}/no/sent/reject`, body);
  },
  async cancelDocument(body) {

    return await client('POST', `${BASE_URL}/cancel`, body);
  },
  async deleteDocument(id) {
    return await client('DELETE', `${BASE_URL}/delete/${id}`);
  },
  async filterDocument(url) {
    return await client(undefined, url);
  },
  async getEmpwerments(tin) {
    return client(undefined, `documents/get/for/agent/${tin}`);
  },
  async getTimeStamp() {
    return client(undefined, `/invoice/get/id?timestamp=${new Date().getTime()}`);
  },
  async createPdfFileNameAndPath(docType, body) {
    return client('POST', `${BASE_URL}/create/pdf/${docType}`, body);
  },
  async viewPdf(data) {
    let path = typeof data === 'string' ? data : data?.data.filePath + '/' + data?.data.fileName;
    return client(undefined, `/documents/get/instant/view/pdf?path=${path}`, undefined, undefined, { responseType: 'blob' });
  },
  async getOldDocuments(tin, key) {
    return await client(undefined, `/documents/get/invoice/old/${tin}?keyword=${key}`);
  },
  async createAndSignCommonDoc(doctype, body) {
    let id = 0;
    return client('POST', `${BASE_URL}/create/${doctype}/${id}`, body);
  },
  async createAndSignThreeSideDoc(body) {
    return client('POST', `${BASE_URL}/create-third-side-contract`, body);
  },
  async createAndSignTaxDoc(doctype, body, id) {
    return client('POST', `${BASE_URL}/create/${doctype}/${id}`, body);
  },
  async signWaybillByRoute({ status, documentId, body }) {
    return await client('POST', `/responsible-persons/sign/${status}/${documentId}`, body)
  },
  async getReport(body) {
    return client('POST', `/report/document`, body, undefined, { responseType: 'blob' });
  },
  async getDocListByBranch(url) {
    return await client(undefined, url);
  },
  async getDocJson(id) {
    return await client(undefined, `/documents/get/content/2/${id}`);
  },
  async sendDocument(body) {
    return await client('POST', `${BASE_URL}/send/uploaded`, body);
  },
  async getTovarClassifications() {
    return await client(undefined, '/classifications');
  },
  async getDocumentTypes() {
    return await client(undefined, '/user/document/types');
  },
  async getDocumentTypesForTemplate() {
    return await client('GET', '/docType/template/types');
  },
  // return await client("GET", `/analytics/get/product/export/${documentId}`, {}, undefined, { responseType: "blob" });
  async getWordDocForEditTemplate(id) {
    return await client('GET', `/docType/template/download/${id}`, {}, undefined, { responseType: 'blob' });
  },
  async uploadFile(params) {
    return await client(
      'POST',
      '/excel/multiple/upload',
      params,
      { 'Content-Type': 'multipart/form-data' },
      undefined,
      'multipart/form-data'
    );
  },
  async getDocCurrentState(id) {
    return await client(undefined, `/edocuments/${id}`);
  },
  async getDocumentTypeCounts(requestBody) {
    return await client('POST', '/documents/get/type/counts', requestBody);
  },
  async getDocumentStateCounts(requestBody) {
    return await client('POST', '/documents/get/state/counts', requestBody);
  },
  async getDocumentCountsByTypeAndPart(requestBody) {
    return await client('POST', '/documents/get/parts/count', requestBody);
  },
  async getDocumentByPartners(requestBody) {
    return await client('POST', '/documents/get/partners', requestBody);
  },
  async downloadDocument(id) {
    return await client(undefined, `/util/document/archive/file/${id}`, undefined, undefined, { responseType: 'blob' });
  },
  async getRentData({ sellerTin, buyerTin }) {
    return await client("GET", `/invoice/rent-doc/${sellerTin}/${buyerTin}`);
  },
  async changeConstructionObject({ constructionObjectId, documentId }) {
    return await client('POST', `/document/change/constructionObject/${documentId}/${constructionObjectId}`);
  },
  async changeDocType({ docType, docId }) {
    return await client('POST', `/document/change/type/${docId}/${docType}`);
  },
  async getEmployeesByDocId(docId) {
    return await client(undefined, `/documents/get/employees/${docId}`);
  },
  async getConstructionObjectsRelatedToDoc(body) {
    return await client('POST', '/documents/get/constructionObjects', body);
  },
  async addEmployeeToDoc({ employeeId, docId }) {
    return await client('POST', `/document/add/employee/${docId}/${employeeId}`);
  },
  async sendApplicationDoc(body) {
    return await client('POST', '/di/documents/proposal/send', body);
  },
  async acceptApplication({ formData, docId }) {
    return await client(
      'POST',
      `/di/documents/proposal/${docId}/accept`,
      formData,
      { 'Content-Type': 'multipart/form-data' },
      undefined,
      'multipart/form-data'
    );
  },
  async viewSubContract(docId) {
    return await client('GET', `/di/documents/subcontract/${docId}/view`, {}, {}, { responseType: 'blob' });
  },
  async getSubContractContentToSign(docId) {
    return await client('POST', `/di/documents/subcontract/contentToSign/${docId}`);
  },
  async getSubContractInfo(docId) {
    return await client('GET', `/di/documents/subcontract/${docId}/info`);
  },
  async acceptSubContract({ body, docId }) {
    return await client('POST', `/di/documents/subcontract/${docId}/accept`, body);
  },
  async rejectSubContract({ body, docId }) {
    return await client('POST', `/di/documents/subcontract/${docId}/reject`, body);
  },
  async syncDocWithSoliq(id) {
    return await client('GET', `/info/check/state/${id}`);
  },
  async changeDocumentState({ id, state } = {}) {
    return await client('GET', `/analytics/change/document/state/${id}/${state}`);
  },
  async analyticsDownloadJson(docId) {
    return await client('GET', `/analytics/download/json/${docId}`);
  },
  async analyticsUploadJson(body = { content: 'json', documentId: 'number' }) {
    return await client('POST', `/analytics/upload/json`, body);
  },
  async analyticsExportExcel(documentId) {
    return await client('GET', `/analytics/get/product/export/${documentId}`, {}, undefined, { responseType: 'blob' });
  },
  async acceptOfferta(offerId) {
    return await client('GET', `/document/accept/offer/${offerId}`);
  },
  async syncUserDataWithPublic() {
    return await client('GET', `/user/sync-public`);
  },
  async getExcelTemplate(hasMultiple, type) {
    const headers = { 'Content-Type': 'blob' };
    const additionalProperties = { responseType: 'arraybuffer' };
    return await client('GET', `/excel/template/only/get/${hasMultiple}/${type}`, {}, headers, additionalProperties);
  },
  async getExtraDocTypes(docType) {
    return await client('GET', `/user/extra/document/type/${docType}`);
  },

  // --------------------- SEND TO TO PERSON FOR PUBLIC SIGN ---------------
  async sendToPublicPerson({ data, id }) {
    data.append('docId', id);
    return await client(
      'POST',
      '/notification/send-to-partner',
      data,
      { 'Content-Type': 'multipart/form-data' },
      undefined,
      'multipart/form-data'
    );
  },

  async getPublicDocInfo({ id }) {
    return await client('GET', `public/document/info/${id}`);
  },
  // NOTE - sign qilishda ishlatiladigan url
  async getPublicSignContent(id) {
    return await client('GET', `/public/signed/content/${id}`);
  },
  // NOTE - reject qilishda ishlatiladigan url
  async getPublicContent(id) {
    return await client('GET', `/public/content/${id}`);
  },
  async viewPublicDocJson(id) {
    return await client('GET', `/public/document/${id}/view`);
  },
  async viewPublicPdfDoc(id) {
    return await client('GET', `/public/document/${id}/view`, {}, {}, { responseType: 'blob' });
  },

  async acceptPublicDocument({ body, docId }) {
    return await client('POST', `/public/${docId}/accept`, body);
  },
  async rejectPublicDocument({ body, docId }) {
    return await client('POST', `/public/${docId}/reject`, body);
  },
  // ------------------------- -------- ------------- ------------
  async switchUi() {
    return await client('GET', `/user/checked/ui`);
  },
  async getDocumentPdfFile(docId) {
    return await client(undefined, 'pdf/document/' + docId, undefined, undefined, { responseType: 'blob' })
  },
  async getPdfDocumentsCount(body) {
    return await client('POST', 'archives/get-count', body);
  },
  async savePdfDocuments(body) {
    return await client('POST', '/archives/save', body);
  },
  async downloadPdfDocumentsAsZipFile(id) {
    return await client('GET', 'archives/download/zip/folder/' + id, {}, {}, { responseType: 'blob' });
  },
  async getArchivedPdfDocList() {
    return await client('GET', '/archives/get-archives');
  },
  async approveDocument({ body, docType }) {
    return await client('POST', `/document/approve?type=${docType}`, body);
  },
  async getSingleSideCatalogcodes() {
    return await client('GET', `/classifications/get/single-side/invoice/mxik`);
  },
  async getDocumentsAsPdf(body) {
    return await client('POST', 'pdf/documents/download/zip', body, {}, { responseType: 'blob' });
  },
  async getContractListsStatesForFilter() {
    return await client('GET', 'contractlist/get-contract/states');
  },
  async getContractListsTypesForFilter() {
    return await client('GET', 'contractlist/get-contract/types');
  },
  async getContractListsCounterAgentsForFilter() {
    return await client('GET', 'contractlist/get-contract/counter-agents');
  },
  async getContactListsObjectForFilter(boxType = 1) {
    return await client('GET', `contractlist/get-contract/construction-objects?boxType=${boxType}`);
  },
  async getContactListsBranchForFilter(boxType = 1) {
    return await client('GET', `contractlist/get-contract/branches?boxType=${boxType}`);
  },
  async getRelevantTinsOfDocument(docId) {
    return await client('GET', 'documents/get/get-signers-list/' + docId);
  },
  async sendPublicDoc(body) {
    return await client('POST', '/public/send-document', body);
  },
  async sendToPublicPersonOnLanding(body) {
    return await client('POST', '/public/send-to-partner', body);
  },
  async synchronizeSeveralDocumentsFromTax(body) {
    return await client('POST', '/tax/synchronize/sync-from-tax', body);
  },
  async terminateDocument(id) {
    return await client('PATCH', `${BASE_URL}/changing/state/${id}/kindergarten`);
  }
};

export default ApiDocument;
