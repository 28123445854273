import React, { memo, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { routes } from './route';

import LayoutDashboard from 'Components/organisms/layout/container';
import SetBdmAsOperatorModal from 'Components/molecules/profileMenu/SetBdmAsOperatorModal';
import Modal from 'Components/atoms/modal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { selectAccountData } from 'Store/selectors/selectors';
import OffertaPdf from 'Components/molecules/pdf/offerta';
import Btn from 'Components/atoms/button';
import ApiUser from 'Services/api/controller/user';
import UserAttentionPopaps from 'Components/molecules/user-attention-popaps';

const Dashboard = () => {
  const { t } = useTranslation(['modalMessages'])
  const isFirstLogin = useSelector((state) => state.accountData.first_login);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('')
  const [hasOfferta, setHasOfferta] = useState(false)
  const [offertaModalvisible, setOffertaModalVisible] = useState(false)
  const [isntBdmOperator, setIsntBdmOperator] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false)

  let location = useLocation()
  const accountData = useSelector(selectAccountData);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {

    if (accountData?.isActive === -1 && accountData?.balance === 0 && accountData?.offerId) {
      // setModalMessage(t('message.withoutOffertaAndInsufficientBalance'))
      // setTimeout(() => showModal(), 3000)
    } else if (accountData?.openBalanceModal) {
      // setModalMessage(t('message.insufficientBalance'))
      // setTimeout(() => showModal(), 3000)
    } else if (accountData?.isActive !== 1 && accountData?.balance > 25000) {//accountData?.isActive !== 1 && accountData?.balance > 25000
      setModalMessage(t('message.bugWithAccountBalance'))
      setTimeout(() => showModal(), 3000)
    } else {
      setIsModalOpen(false);
    }

  }, [location])

  const modalFooter = () => {
    if (accountData?.isActive === -1 && accountData?.balance === 0 && accountData?.offerId) {
      return <Btn onClick={() => setOffertaModalVisible(true)}>{t('action.acceptOfferta')}</Btn>
    } else if (accountData?.openBalanceModal) {
      return;
    } else if (accountData?.isActive !== 1 && accountData?.balance > 25000) {
      return <a style={{ fontSize: '18px' }} target="_blank" href='https://t.me/DHR_UZ'>{t('link.linkToTelegram')}</a>
    }
  }

  useEffect(() => {
    const tin = accountData?.pinfl ? accountData?.pinfl : accountData?.tin;
    tin && ApiUser.getBDMOperator(tin).then((res) => {
      if (!res.fail && !res?.data?.data?.includes('305858074')) {
        setIsntBdmOperator(true);
      }
    });

    if (tin && tin?.includes('BDM-')) {
      setIsEmailUser(true)
    }
  }, [accountData])

  return (
    <LayoutDashboard>
      {routes.map((route, id) => (
        <Route path={route.path} key={id} component={route.component} />
      ))}
      <Modal
        title={t('title.warning')}
        visible={isModalOpen}
        color={"#c10606"}
        onClose={handleCancel}
        footer={modalFooter()}
      >
        <p style={{ fontSize: "20px" }}>{modalMessage}</p>
      </Modal>
      <OffertaPdf
        setHasOfferta={setHasOfferta}
        hasOfferta={hasOfferta}
        offerId={accountData?.offerId}
        visible={offertaModalvisible}
        setVisible={setOffertaModalVisible}
      />
      <SetBdmAsOperatorModal visible={isntBdmOperator && isFirstLogin && (!isEmailUser)} />
      <UserAttentionPopaps />
    </LayoutDashboard>
  );
};

Dashboard.whyDidYouRender = true;

export default memo(Dashboard);
