import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { showEimzoSessionEndModal } from 'Components/atoms/toast/EimzoSessionEndModal';
import browserStorage from 'Services/browserStorage';
import logout from 'Utils/logout';

export const cachedUrls = ['/user', '/info/measuries/list'];

// export const BASE_URL = 'http://192.168.1.206:8081/api';
// export const BASE_URL = "http://192.168.1.210:8081/api";
// export const BASE_URL = "http://192.168.1.164:9091/api";
export const BASE_URL = 'https://api-beta.24m.uz/api';
// export const BASE_URL = "http://api-test.24m.uz/api"; // CORP TEST
// export const BASE_URL = "http://192.168.1.132:8081/api";
// export const BASE_URL = 'https://26c0-178-218-206-18.ngrok-free.app/api';
// export const ONLY_BASE_URL = BASE_URL === 'http://api-test.24m.uz/api' ? 'http://api-test.24m.uz' : BASE_URL.replace('/api', ''); // CORP TEST
export const ONLY_BASE_URL = BASE_URL === 'https://api-beta.24m.uz/api' ? 'https://api-beta.24m.uz' : BASE_URL.replace('/api', '');

// NOTE cache config -------

export const cache = setupCache({
  limit: false,
  exclude: {
    filter: (arg) => {
      if (cachedUrls.indexOf(arg.url) === -1) return true;
      return false;
    },
    query: true
  }
});

// NOTE axios base config

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  responseType: 'application/json',
  adapter: cache.adapter
});

// NOTE request interceptors -----

const REF_OBJ = { abort: () => {} };

function getTokenReqAndSave() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  REF_OBJ.abort = source.cancel;

  return source.token;
}

Axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${browserStorage.get('token')?.accessToken}`;

    let lang = localStorage.getItem('lang');
    lang = lang === 'cyrillic' ? 'uzcyrill' : lang === 'latin' ? 'uzlatin' : 'ru';

    config.headers.lang = lang;

    if (
      config.url.includes('info/users/requisite/list') ||
      config.url.includes('classifications/search?keyword') ||
      config.url.includes('/ai/get/data') ||
      config.url.includes('documents/get/partners')
    ) {
      // NOTE oldingi zaprosga cancel berib hozirgisini cancel func ni saqlaymiz
      REF_OBJ.abort('manual_abort_request');
      config.cancelToken = getTokenReqAndSave();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// NOTE response interceptors -----

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      // notification.setType('error').setMessage('Сессия E-IMZO завершилась').alert();
      /* setTimeout(() => {
        showEimzoSessionEndModal();
      }, 1500); */
      logout();
    }
    return Promise.reject(error);
  }
);

export default Axios;
